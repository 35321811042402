<template>
	<div class="pageContainer">
		<web-header></web-header>
		<div class="content">
			<router-title :menuList="menuList[0]" :list="menuList[1]" />
			<div class="allItem">
					<div v-for="(item,index) in list" :key="index" class="item">
						<img :src="item.surfacePlot" alt="">
						<div class="name">{{item.name}}</div>
						<div class="live_time">{{item.beginTime}}</div>
						<div class="mode">
							<!-- 正在直播 -->
							<div class="btn living_btn" v-if="item.status == 1 && item.stuToLive"
								@click="watchInfo(item.id)">
								<span>正在直播</span>
							</div>
							<!-- 已预约 -->
							<div class="btn" v-if="item.status == 0 && item.stuToLive">
								<span>已预约</span>
							</div>
							<!-- 已结束 -->
							<div class="btn" v-if="item.status === 2 && item.stuToLive">

								<span>已结束</span>
							</div>
							<!-- 立即预约 -->
							<div class="btn reserve_btn" v-if="!item.stuToLive" @click="toYuyue(item.id)">
								<span>立即预约</span>
							</div>
						</div>
						<div  v-if="item.status == 1 && item.stuToLive" class="living" 	@click="watchInfo(item.id)">
							<img src="../../assets/course/live.png" alt="">
							正在直播</div>
					</div>
			</div>
				<div class="pagination">
					<el-pagination background layout="prev, pager, next" :page-size="pageSize"
						@current-change="handleCurrentChange" :page-count="count">
					</el-pagination>
				</div>
		</div>
		<web-footer></web-footer>
	</div>
</template>

<script>
	import WebFooter from '../../components/webFooter.vue'
	import webHeader from '../../components/webHeader.vue'
	import RouterTitle from "../../components/routerTitle.vue";
	import {
		mapState
	} from 'vuex';
	export default {
		components: {
			webHeader,
			WebFooter,
			RouterTitle
		},
		data() {
			return {
				menuList: ["首页", "直播课堂"],
				list: [
				],
				pageSize: 12,
				pageNumber: 1,
				count: 0,
				loading: ''
			}
		},
		computed: {
			...mapState(['isLogin'])
		},
		created() {
			this.getByPage()
		},
		methods: {
			//去直播详情
			watchInfo(id) {
				if (!this.isLogin) return this.$message.error('请先登录')
				this.$router.push({
					path: '/liveInfo',
					query: {
						id
					}
				})
			},
			getByPage() {
					const loading = this.$loading({
					lock: true,
					text: "Loading",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				let data = {
					pageSize: this.pageSize,
					pageNumber: this.pageNumber
				}
				this.$http.post('liveGetByPage', data).then((res) => {
					if (res.code == 200 && res.data) {
						this.list = res.data.records
						this.count = res.data.pages
						loading.close();
						console.log(11111111111111111);
					} else {
						this.$message.error(res.message);
						loading.close();
					}
				})
			},
			toYuyue(id) {
				if (!this.isLogin) return this.$message.error('请先登录')
				this.$router.push({
					path: '/livedetails',
					query: {
						id
					}
				})
			},
			handleCurrentChange(e) {
				this.pageNumber = e
				this.getByPage()
			}
		}
	}
</script>

<style lang="less" scoped>
	.content {
		position: relative;
		width: 1200px;
		background: #Fff;
		margin: auto;
		.allItem{
			min-height: 650px;
				.item {
			float: left;
			position:relative;
			height: 210px;
			width: 177px;
			margin-right: 26px;
			margin-bottom: 32px;
			img {
				width: 177px;
				height: 127px;
				border-radius: 0px 0px 20px 0px;
			}
			.name{
				padding: 5px;
				height: 44px;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #333333;
				 text-overflow: -o-ellipsis-lastline;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					line-clamp: 2;
					-webkit-box-orient: vertical;
			}
			.live_time{
				font-size: 1px;
				font-family: Microsoft YaHei;
				font-weight: 300;
				color: #999999;
				line-height: 24px;
				margin-top: 10px;
			}
			.mode{
				display: none;
				position: absolute;
				top: 0;
				left: 0;
					width: 177px;
				height: 127px;
				background-color: #000000;
				opacity: 0.3;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				border-radius: 0px 0px 20px 0px;
				.btn{
						text-align: center;
					margin-top:53px;
					margin-left: 40px;
					width: 80px;
					height: 25px;
					border: 1px solid #FFFFFF;
					border-radius: 0px 6px 0px 6px;
					cursor: pointer;
				}
			}
			.living{
				position: absolute;
				top: 0;
				left: 0;
				width: 80px;
				height: 25px;
				background: #000000;
				opacity: 0.5;
				line-height: 25px;
				text-align: center;
				border-radius: 0px 6px 0px 6px;
				font-size: 12px;
				font-weight: 400;
				color: #FFFFFF;
				img {
					width: 11px;
					height: 12px;
				}
			}
		}
		.item:hover .mode {
			display: block;
		}
		.item:nth-child(6n) {
				margin-right: 0;
			}
		}
	
		.pagination {
			position:absolute;
			bottom: 100px;
			width: 100%;
			text-align: center;
			/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
				background: #39AEFF;
			}
		}
	}
</style>
